// 定数定義ファイル
export default {
    USER_TYPE: {
        ADMIN: 0, // 管理者
        USER: 1,  // 医療機関
        USER_ADMIN: 2, // IVチームがユーザーアカウントにログインするためのもの
        READONLY: 3 // 読み取り専用
    },
    USER_STATUS: {
        0: '電話番号未申請',
        1: '電話番号申請中',
        2: '電話番号失敗差し戻し中',
        3: 'コールフロー紐づけ完了',
        4: 'テストコール確認済',
        9: '削除済',
    },
    CROWD_SETTING: {
        0: '標準設定',
        1: '手動設定',
    },
    CLOSE_ANNOUNCE_SETTING: {
        0: 'すべてのメニューを停止し休診案内を流す',
        1: '一部メニューを停止する',
    },
    FEE_STATUS: {
        0: '未確定',
        1: '確定済み',
        2: '確定済み',
    },
    RESPONSE_TYPE: {
        0: '詳細分岐',
        1: '音声案内',
        2: 'SMS送信',
        3: '電話転送',
        4: '混雑状況',
        5: '音声録音',
    },
    RESPONSE_TYPE_MESSAGE: {
        0: '更に分岐する',
        1: '音声ガイダンスを流す',
        2: '音声ガイダンスの後SMSを送信する',
        3: '電話を転送する',
        4: '混雑状況を案内する',
        5: '音声を録音する',
    },
    DAY_OF_WEEK: {
        0: '日',
        1: '月',
        2: '火',
        3: '水',
        4: '木',
        5: '金',
        6: '土',
    },
    MESSAGE_TYPE: {
        0: '固定',
        1: '空',
        2: '中',
        3: '混',
    },
    TOOLTIP_SETTING_CREATE: {
        'MOVE': 'ドラッグアンドドロップで順序を変更できます。',
        'EDIT': '音声ガイダンスの読み上げテキスト及び送信するSMSのテキストを編集します。',
        'OPENED': '[ON]を設定すると、診療時に実行されます。',
        'CLOSED': '[ON]を設定すると、休診時でも休診時設定で[一部メニューを停止する]が選択されている場合には実行されます。',
        //'ENABLE': '[ON]を設定すると、設定項目は残りますが、実際のメニューとして実行されることはありません。',
    },
    SUPPORT_PAGE_LINK: 'https://console.aws.amazon.com/support/home#/case/create',
    AMAZON_CONNECT_LINK: 'https://medical-ivr-staging.my.connect.aws',
    PHONE_REQUEST_MANUAL:"電話番号申請マニュアル.pdf",
    PHONE_LINK_MANUAL:'電話番号紐づけマニュアル.pdf',
    FACILITY_ATTRIBUTES: [
        'FacilityName',
        'FacilityNameKana',
        'ConnectedPhoneNumber',
        'MainPhoneNumber',
        'TransferPhoneNumber',
        'PostalCode',
        'Prefecture',
        'Address',
        'URL',
        'Mail',
        'LineURL',
        'InitialMessage',
        'RegistrationStatus',
        'CrowdSetting',
        'CloseAnnounceSetting',
        'CalciumUserID',
        'HospitalGuid'
    ],
    RESPONSE_SETTINGS_ATTRIBUTES: [
        'Order',
        'DispNumber',
        'Name',
        'ResponseType',
        'VoiceMessage',
        'SMSMessage',
        'TransferPhoneNumber',
        'NotifyWhenVoiceMail',
        'NotifyPhoneNumber',
        'NotifyMailAddress',
        'NotifyMultiAddresses',
        'EnableAutoCall',
        'ActiveWhenClosed',
        'TransferWhenClosed',
        'Enable',
        'ActiveWhenOpened',
        'SMSAlternateMessage',
        "TransferType",
        "TransferTargetID",
        "TransferFailedNotify"
    ],
    RESPONSE_SETTINGS_TEMPLATE_ATTRIBUTES: [
        'Order',
        'DispNumber',
        'Name',
        'ResponseType',
        'VoiceMessage',
        'SMSMessage',
        'SMSAlternateMessage',
        'TransferPhoneNumber',
        'NotifyWhenVoiceMail',
        'NotifyPhoneNumber',
        'NotifyMailAddress',
        'ActiveWhenOpened',
        'ActiveWhenClosed',
        'TransferWhenClosed',
        'Enable',
        'MenuName',
    ],
    OFFICE_HOURS_ATTRIBUTES: [
        'DayOfWeek',
        'Closed',
        'AmFrom',
        'AmTo',
        'PmFrom',
        'PmTo',
    ],
    CUSTOM_OFFICE_HOURS_ATTRIBUTES: [
        'Day',
        'AllDay',
        'Closed',
        'From',
        'To'
    ],
    CROWD_SETTING_ATTRIBUTES: [
        'DayOfWeek',
        'AMPM',
        'Part',
        'MessageType',
    ],
    CROWD_DEFAULT_MESSAGE_ATTRIBUTES: [
        'CrowdStatus',
        'Message',
    ],
    CROWD_CUSTOM_MESSAGE_ATTRIBUTES: [
        'Message',
        'Enable',
        'Order',
    ],
    NOTIFICATION_ATTRIBUTES: [
        'NotificationTitle',
        'NotificationMessage',
        'Emergency'
    ],
    WHITELIST_ATTRIBUTES: [
        'WhitelistName',
        'WhitelistPhoneNumber',
        'WhitelistTransferPhoneNumber'
    ],
    BLACKLIST_ATTRIBUTES: [
        'BlacklistName',
        'BlacklistPhoneNumber',
    ],
    CALL_VIA_IVER_ATTRIBUTES: [
        "ViaIverCallName",
        "ViaIverCallPhoneNumber"
    ],
    TRANSFER_ADDRESS_ATTRIBUTES: [
        'TransferAddressName',
        'TransferAddressPhoneNumber'
    ],
    DEFAULT_TRANSFER_TARGET_ATTRIBUTES: [
        'TargetID',
        'TargetType'
    ],
    TRANSFER_TARGET_PATTERN_ATTRIBUTES: [
        'Type',
        'Order',
        'PatternName',
        'TransferAddressID',
    ],
    TRANSFER_SCHEDULE_ATTRIBUTES: [
        'DayOfWeek',
        'Start',
        'End',
        'TargetID',
        'TargetType'
    ],
    CUSTOM_TRANSFER_SCHEDULE_ATTRIBUTES: [
        'Day',
        'Start',
        'End',
        'TargetID',
        'TargetType'
    ],
    SUPPORT_FAQ_ATTRIBUTES: [
        'Title',
        'Question',
        'Answer'
    ],
    SUPPORT_SETTING_ATTRIBUTES: [
        'Title',
        'Url',
    ],
    SUPPORT_WINDOW_ATTRIBUTES: [
        'Title',
        'FileName',
    ],
    SUPPORT_WINDOW_SUB_ATTRIBUTES: [
        'GroupName',
        'Title',
        'Description',
        'FileName',
    ],
    INITIAL_ATTRIBUTES: {
        callFlowEndMessage: '<speak>お電話ありがとうございました。</speak>',
        phoneTransferTransferOffMessage: '<speak>ただいま休診中となります。申し訳ございませんが受付時間中にもう一度ご連絡をお願いいたします。</speak>',
        smsAfterMessage: '<speak><s>ショートメッセージをお送りいたしました。</s><s>電話を切ってお待ち下さい。</s><s>ショートメッセージを受信するまでに、数分かかる場合がございます。</s></speak>',
        smsAlternateAfterMessage: '<speak><s>お掛けになった電話番号はSMS送信に対応しておりません。</s></speak>',
        reInputMessage: '<speak><s>無効な番号です</s>もう一度入力してください。</speak>',
        returnMenuMessage: '<speak><break time="1s"/>メニューに戻る場合は、9番を、押してください</speak>',
        phoneNoConnectMessage:'<speak>大変申し訳ございません。ただいま電話が混雑しており、お繋ぎすることができませんでした。改めて時間をおいておかけ直しいただきますよう、お願い致します。</speak>',
        callFlowErrorMessage: '<speak>音声案内システム内でエラーが発生しました。ご迷惑をおかけして申し訳ありません。しばらく時間をおいてから再度お試しください。</speak>',
        menuTimeoutMessage: '<speak>入力が確認できませんでした。自動的にお電話をお切りいたしますので、再度おかけ直しをお願いいたします。</speak>',
        menuTimeoutTransferMessage: '<speak>入力が確認できませんでした。直接転送します。</speak>',
        whitelistMessage: '<speak>院内へ直接転送します。</speak>',
        recordingStartMessage: '<speak>60秒以内でお話しください。お話しが完了した後は、お電話を切らずに、シャープを押してください。</speak>',
        recordingEndMessage: '<speak>メッセージを録音いたしました。</speak>',
        transferRecordingMessage: '<speak>電話応対の品質向上のため、この通話は録音させていただきます。ご了承ください。</speak>',
        callViaIverMenuMessage: "<speak><p><phoneme alphabet='x-amazon-pron-kana' ph='ア&apos;イバー'>Iver</phoneme>の番号で発信する方は、1番を</p><p>通常のメニューを聞きたい方は、2番を</p><s>もう一度メニューを聞きたい方は、<phoneme alphabet='x-amazon-pron-kana' ph='ゼロ&apos;'>0</phoneme>番を、押してください。</s></speak>",
        phoneNumberInputMessage: "<speak><phoneme alphabet='x-amazon-pron-kana' ph='ア&apos;イバー'>Iver</phoneme>の番号で発信を行います。<phoneme alphabet='x-amazon-pron-kana' ph='ピーッ'>ピーッ</phoneme>という音の後に、お繋ぎしたい電話番号をご入力ください。入力が完了したら、シャープを押してください。</speak>",
        phoneConfirmationMessage: "<speak><p><say-as interpret-as='telephone'>$.Attributes.InputPhoneNumber</say-as>へ転送する場合<s>は、1番を</s></p><p>もう一度番号を入力する場合<s>は、2番を</s></p><s>もう一度メニューを聞きたい方は、<phoneme alphabet='x-amazon-pron-kana' ph='ゼロ&apos;'>0</phoneme>番を、押してください。</s></speak>"
    },
    OFFICE_HOURS_VALUES: {
        'Closed': 'FALSE',
        'AmFrom': '900',
        'AmTo': '1200',
        'PmFrom': '1500',
        'PmTo': '1800',
    },
    CROWD_DEFAULT_MESSAGES_VALUES: {
        0: '混雑している可能性があります。お時間に余裕をもってお越しください。',
        1: '現在は空いております。30分以内にご案内可能です。',
        2: '少し混雑しております。30分ほどお待たせしてしまいます。',
        3: 'かなり混雑しております。1時間近く待ち時間が発生しております。',
        4: '只今の時間は休診中となっております。',
    },
    CROWD_CUSTOM_MESSAGES_VALUES: {
        'Message': '現在は空いております。30分以内にご案内可能です。',
        'Enable': 'TRUE',
        'Order': '0',
    },
    ADMIN_MAIL: 'iver@layered.inc',
    TOTAL_TEXT_COUNT_LIMIT: 5000,
    TOTAL_MENU_LIMIT: 30,
    TAX: 1.1,
    AES_KEY: 'q2UeEW4Z',
    INVOICE_START_YEAR_MONTH: 202303,
    DEFAULT_GPT_SUMMARY_ROWS: 6,
    ACCOUNT_ACTION_TYPE: {
        CREATE: "create",
        RECOVER: "recover"
    }
}