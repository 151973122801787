import { createApp } from 'vue'
import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs.js'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import aws_exports from './aws-exports'
import Amplify, {Analytics} from 'aws-amplify'
import BootstrapVueNext from 'bootstrap-vue-next'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import Vue3Sanitize from "vue-3-sanitize"
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import './utils/common'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
library.add(faXmark)

Amplify.configure(aws_exports)
console.log(Analytics._disabled)

const defaultOptions = {
  allowedTags: ['br','b','u','i', 'a', 'img', 'font', 'span'],
  allowedAttributes: {
    'a': ['href', 'target'],
    'img': ['src', 'alt', 'title', 'width', 'height'],
    'font': ['size', 'color'],
    'span': ['style']
  }
}

const i18n = createI18n({
  locale: "ja",
});

const app = createApp(App, {
  compatConfig: {
    COMPONENT_V_MODEL: false
  }
})

app
  .component('DatePicker', VueDatePicker)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(Vue3Sanitize, defaultOptions)
  .use(BootstrapVueNext)
  .use(i18n)
  .use(router)
  .use(store)
  .mount('#app')
