<template>
  <div class="modal-mask" v-show="$store.getters.loading">
    <div class="loading" >
      <loading 
        loader="spinner"
        :active="$store.getters.loading" 
        color="#0CA287" 
        :width="80" 
        :height="80" 
        :opacity="0.2"
      />
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
export default {
  components: {
    Loading
  }
}
</script>

<style scoped>
.modal-mask {
  /* �ő�l */
  z-index:2147483647;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>