import { API, graphqlOperation } from "aws-amplify"
import { listMivrDataByDataType } from "@/graphql/queries"
import { addOperationLogs } from "@/utils/common"
import CONST from "@/assets/setting/constant"

const CALL_VIA_IVER_ATTRIBUTES = CONST.CALL_VIA_IVER_ATTRIBUTES

const state = {
  callViaIverNameById: {},
  callViaIverIdByPhoneNumber: {}
}

const getters = {
  getCallViaIverNameByPhoneNumberIfFound: (state) => (phoneNumber) => {
    const id = state.callViaIverIdByPhoneNumber[phoneNumber]

    if (id && state.callViaIverNameById[id]) {
      return state.callViaIverNameById[id]
    }

    return phoneNumber
  }
}

const mutations = {
  setCallViaIverNameById(state, callViaIverNameById) {
    state.callViaIverNameById = callViaIverNameById
  },
  setCallViaIverIdByPhoneNumber(state, callViaIverIdByPhoneNumber) {
    state.callViaIverIdByPhoneNumber = callViaIverIdByPhoneNumber
  }
}

const actions = {
  buildCallsViaIverMaps({ commit }, items) {
    const callViaIverNameById = {}
    const callViaIverIdByPhoneNumber = {}

    for (const item of items) {
      const { ID, AttributeType, AttributeValue } = item

      if (AttributeType === CALL_VIA_IVER_ATTRIBUTES[0]) {
        callViaIverNameById[ID] = AttributeValue
      }

      if (AttributeType === CALL_VIA_IVER_ATTRIBUTES[1]) {
        callViaIverIdByPhoneNumber[AttributeValue] = ID
      }
    }

    commit("setCallViaIverNameById", callViaIverNameById)
    commit("setCallViaIverIdByPhoneNumber", callViaIverIdByPhoneNumber)
  },
  async fetchCallsViaIver({ dispatch }, facilityId, page) {
    const condition = { DataType: "CallViaIver", FacilityID: { eq: facilityId }, limit: 40000 }
    try {
      const { data } = await API.graphql(graphqlOperation(listMivrDataByDataType, condition))
      const items = data.listMivrDataByDataType.items
      
      dispatch("buildCallsViaIverMaps", items)
    } catch (error) {
      await addOperationLogs("Error", page, "fetchCallsViaIver", {}, error)
    }
  }
}

export default {
  namespaced: true, // ストアのモジュール間での名前の衝突を避ける
  state,
  mutations,
  actions,
  getters
}