import { createRouter, createWebHistory } from 'vue-router'
import { Auth } from 'aws-amplify'
import store from '../store'
import { addOperationLogs, fetchUserType } from '@/utils/common.js'
import $ from 'jquery';
import CONST from '@/assets/setting/constant';
import crypto from 'crypto-js';

const routes = [
  {
    path: '/',
    redirect: '/ivr-login',
    name: 'ROOT',
  },
  {
    path: '/login',
    name: 'LOGIN',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue'),
    meta: { title: 'Iver | 管理者ログイン', desc: 'IVR-ADMINのログイン画面です', type: 'login', isPublic: true },
  },
  {
    path: '/clinic-list',
    name: 'CLINIC-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/clinic-list.vue'),
    meta: { title: 'Iver | 医療機関一覧', desc: 'IVR-ADMINの医療機関一覧画面です', type: 'admin' },
  },
  {
    path: '/admin-list',
    name: 'ADMIN-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin-list.vue'),
    meta: { title: 'Iver | 管理者一覧', desc: 'IVR-ADMINの管理者一覧画面です', type: 'admin' },
  },
  {
    path: '/fee-list',
    name: 'FEE-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/fee-list.vue'),
    meta: { title: 'Iver | 利用料金確認', desc: 'IVR-ADMINの利用料金確認画面です', type: 'admin' },
  },
  {
    path: '/history-list',
    name: 'HISTORY-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/history-list.vue'),
    meta: { title: 'Iver | 対応履歴一覧', desc: 'IVR-ADMINの対応履歴一覧画面です', type: 'admin' },
  },
  {
    path: '/setting-list',
    name: 'SETTING-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/setting-list.vue'),
    meta: { title: 'Iver | 音声フロー設定一覧', desc: 'IVR-ADMINの音声フロー設定一覧画面です', type: 'admin' },
  },
  {
    path: '/ivr-basic-setting',
    name: 'IVR-BASIC-SETTING',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-basic-setting.vue'),
    meta: { title: 'Iver | 基本情報設定', desc: '医療機関の基本情報設定画面です。', type: 'user' },
  },
  {
    path: '/ivr-crowded-setting',
    name: 'IVR-CROWDED-SETTING',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-crowded-setting.vue'),
    meta: { title: 'Iver | 混雑状況登録', desc: '医療機関の混雑状況登録画面です。', type: 'user' },
  },
  {
    path: '/ivr-fee-list',
    name: 'IVR-FEE-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-fee-list.vue'),
    meta: { title: 'Iver | 利用料金確認', desc: '医療機関の利用料金確認画面です。', type: 'user' },
  },
  {
    path: '/ivr-history',
    name: 'IVR-HISTORY',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-history.vue'),
    meta: { title: 'Iver | 対応一覧', desc: '医療機関の対応一覧画面です。', type: 'user' },
  },
  {
    path: '/ivr-login',
    name: 'IVR-LOGIN',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-login.vue'),
    meta: { title: 'Iver | ログイン', desc: '医療機関のログイン画面です。', type: 'login', isPublic: true },
  },
  {
    path: '/ivr-menu',
    name: 'IVR-MENU',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-menu.vue'),
    meta: { title: 'Iver | メインメニュー', desc: '医療機関のメインメニューです。', type: 'user' },
  },
  {
    path: '/ivr-setting-create',
    name: 'IVR-SETTING-CREATE',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-setting-create.vue'),
    meta: { title: 'Iver | 自動音声設定編集', desc: '医療機関の自動音声設定編集画面です。', type: 'user' },
  },
  {
    path: '/ivr-setting',
    name: 'IVR-SETTING',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-setting.vue'),
    meta: { title: 'Iver | 自動音声設定', desc: '医療機関の自動音声設定画面です。', type: 'user' },
  },
  {
    path: '/ivr-time-setting',
    name: 'IVR-TIME-SETTING',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-time-setting.vue'),
    meta: { title: 'Iver | 対応時間・休診登録', desc: '医療機関の対応時間・休診時間の登録画面です。', type: 'user' },
  },
  {
    path: '/ivr-transfer-schedule',
    name: 'IVR-TRANSFER-SCHEDULE',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-transfer-schedule.vue'),
    meta: { title: 'Iver | 転送スケジュール設定', desc: '医療機関の転送スケジュール設定画面です。', type: 'user' },
  },
  {
    path: '/ivr-support',
    name: 'IVR-SUPPORT',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-support.vue'),
    meta: { title: 'Iver | 各種サポート', desc: '医療機関の各種サポート画面です。', type: 'user', isPublic: true },
  },
  {
    path: '/ivr-support/setting',
    name: 'IVR-SUPPORT-SETTING',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-support-setting.vue'),
    meta: { title: 'Iver | 設定・使い方', desc: '医療機関の設定・使い方サポート画面です。', type: 'user', isPublic: true },
  },
  {
    path: '/ivr-support/window',
    name: 'IVR-SUPPORT-WINDOW',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-support-window.vue'),
    meta: { title: 'Iver | 画面の見方', desc: '医療機関の画面の見方サポート画面です。', type: 'user', isPublic: true },
  },
  {
    path: '/ivr-support/price',
    name: 'IVR-SUPPORT-PRICE',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-support-price.vue'),
    meta: { title: 'Iver | 料金・契約', desc: '医療機関の料金・契約サポート画面です。', type: 'user', isPublic: true },
  },
  {
    path: '/ivr-support/template',
    name: 'IVR-SUPPORT-TEMPLATE',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-support-template.vue'),
    meta: { title: 'Iver | 自動音声メニュー例', desc: '医療機関の自動音声メニュー例サポート画面です。', type: 'user', isPublic: true },
  },
  {
    path: '/ivr-whitelist-setting',
    name: 'IVR-WHITELIST-SETTING',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-whitelist-setting.vue'),
    meta: { title: 'Iver | ホワイトリスト設定', desc: '医療機関のホワイトリスト設定画面です。', type: 'user' },
  },
  {
    path: '/ivr-blacklist-setting',
    name: 'IVR-BLACKLIST-SETTING',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-blacklist-setting.vue'),
    meta: { title: 'Iver | ブラックリスト設定', desc: '医療機関のブラックリスト設定画面です。', type: 'user' },
  },
  {
    path: '/ivr-account-management',
    name: 'IVR-ACCOUNT-MANAGEMENT',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-account-management.vue'),
    meta: { title: 'Iver | 読み取り専用アカウント作成', desc: '医療機関の読み取り専用アカウント作成画面です。', type: 'user' },
  },
  {
    path: '/ivr-call-setting-via-iver',
    name: 'IVR-CALL-SETTING-VIA-IVER',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-call-setting-via-iver.vue'),
    meta: { title: 'Iver | Iver経由で発信できる電話番号設定', desc: '医療機関のIver経由で発信できる電話番号設定画面です。', type: 'user' },
  },
  {
    path: '/ivr-ccp',
    name: 'IVR-CCP',
    component: () => import(/* webpackChunkName: "about" */ '../views/ivr-ccp.vue'),
    meta: { title: 'Iver | 電話発信', desc: '医療機関の電話発信画面です。', type: 'user' },
  },
  {
    path: '/menu',
    name: 'MENU',
    component: () => import(/* webpackChunkName: "about" */ '../views/menu.vue'),
    meta: { title: 'Iver | メインメニュー', desc: 'IVR-ADMINのメインメニューです。', type: 'admin' },
  },
  {
    path: '/request-tel',
    name: 'REQUEST-TEL',
    component: () => import(/* webpackChunkName: "about" */ '../views/request-tel.vue'),
    meta: { title: 'Iver | 電話番号申請', desc: 'IVR-ADMINの電話番号申請画面です', type: 'admin' },
    props:true
  },
  {
    path: '/notification-setting',
    name: 'NOTIFICATION-SETTING',
    component: () => import(/* webpackChunkName: "about" */ '../views/notification-setting.vue'),
    meta: { title: 'Iver | お知らせ管理', desc: 'IVR-ADMINのお知らせ管理画面です', type: 'admin' },
  },
  {
    path: '/support-admin-faq',
    name: 'SUPPORT-ADMIN-FAQ',
    component: () => import(/* webpackChunkName: "about" */ '../views/support-admin-faq.vue'),
    meta: { title: 'Iver | FAQページ管理', desc: 'IVR-ADMINのFAQページ管理画面です', type: 'admin' },
  },
  {
    path: '/support-admin-setting',
    name: 'SUPPORT-ADMIN-SETTING',
    component: () => import(/* webpackChunkName: "about" */ '../views/support-admin-setting.vue'),
    meta: { title: 'Iver | 設定・使い方ページ管理', desc: 'IVR-ADMINの設定・使い方ページ管理画面です', type: 'admin' },
  },
  {
    path: '/support-admin-window',
    name: 'SUPPORT-ADMIN-WINDOW',
    component: () => import(/* webpackChunkName: "about" */ '../views/support-admin-window.vue'),
    meta: { title: 'Iver | 画面の見方ページ管理', desc: 'IVR-ADMINの画面の見方ページ管理画面です', type: 'admin' },
  },
  {
    path: '/support-admin-price',
    name: 'SUPPORT-ADMIN-PRICE',
    component: () => import(/* webpackChunkName: "about" */ '../views/support-admin-price.vue'),
    meta: { title: 'Iver | 料金・契約ページ管理', desc: 'IVR-ADMINの料金・契約ページ管理画面です', type: 'admin' },
  },
  {
    path: '/support-admin-template',
    name: 'SUPPORT-ADMIN-TEMPLATE',
    component: () => import(/* webpackChunkName: "about" */ '../views/support-admin-template.vue'),
    meta: { title: 'Iver | メニュー例ページ管理', desc: 'IVR-ADMINのメニュー例ページ管理画面です', type: 'admin' },
  },
  {
    path: '/support-admin-template-create',
    name: 'SUPPORT-ADMIN-TEMPLATE-CREATE',
    component: () => import(/* webpackChunkName: "about" */ '../views/support-admin-template-create.vue'),
    meta: { title: 'Iver | メニュー例ページ管理', desc: 'IVR-ADMINのメニュー例ページ管理画面です', type: 'admin' },
  },
  {
    path: '/support-admin-template-create/:menuName',
    name: 'SUPPORT-ADMIN-TEMPLATE-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/support-admin-template-create.vue'),
    meta: { title: 'Iver | メニュー例ページ管理', desc: 'IVR-ADMINのメニュー例ページ管理画面です', type: 'admin' },
  },
  // 録音詳細確認画面で/tmp/の後がランダムなのでワイルドカードで拾う
  {
    path: '/tmp/:catchAll(.*)',
    name: 'TMP-VOICE-MAIL-DETAIL',
    component: () => import(/* webpackChunkName: "about" */ '../views/tmp-voice-mail-detail.vue'),
    meta: { title: 'Iver | 録音確認', desc: '医療機関の録音詳細確認画面です', type: 'user', isTmpVoiceMail: true },
  },
  // 上からルーティングを見ていくので、存在しないパスにアクセスされた際はここに引っかかってログインページに飛ばす
  {
    path: '/:catchAll(.*)',
    redirect: '/ivr-login',
    name: 'NOT-FOUND',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: async (to, from) => {
    if (to.path == from.path) {
      // ページリロードはページ先頭に持ってく
      return { x: 0, y: 0 }
    }
    if (to.hash) {
      // ページ要素がアンカーされている時
      let target = $(to.hash).offset().top - 90; // 見栄えのために上をヘッダー+20px空けてる
      return { x: 0, y: target }
    }
    // サポートページ遷移時の自動スクロールを廃止する
    // if (to.path.indexOf('/ivr-support') != -1) {
    //   // サポートページ配下のページはサポートヘッダーより下にあらかじめスクロールさせる
    //   const SUPPORT_HEADER_HEIGHT = 520;
    //   return { x: 0, y: SUPPORT_HEADER_HEIGHT }
    // }
    return { x: 0, y: 0 }
  }
})

// ルーティングトリガー時処理
router.beforeEach(async (to, from, next) => {
  try {
    // ローディングエフェクトON
    store.commit("setLoading", true);

    // 録音確認詳細画面(認証なしでアクセスさせる場合もある特殊画面)
    if (to.matched.some(record => record.meta.isTmpVoiceMail)) {
      // /tmp/の部分を削除して暗号化されたタイムスタンプを取り出し
      let urlParam = to.path.slice(5, to.path.length);
      let timeStampEncrepted = urlParam.split('_')[1];

      // 複合してRegistTranscribeResult Lambdaにてカットされた末尾000を復活させる
      let timeStampDecrepted = crypto.AES.decrypt(timeStampEncrepted, CONST.AES_KEY).toString(crypto.enc.Utf8) + '000';
      let now_ms = new Date().getTime();
      let past_ms = now_ms - timeStampDecrepted;
      /*
        1minute = 60000ms
        1hour = 60minutes = 3600000ms
      */
      let past_hour = past_ms / 3600000;
      console.log('past_hour : ' + past_hour)
      // 現在時刻が12h以内であればアクセスさせる
      if (past_hour <= 12) {
        next();
      } else {
        alert("URL発行より12時間経っているので表示できません。\nログインしてから対応一覧画面にて確認してください。");
        next({ path: '/ivr-login' });
      }
      return;
    }

    // console.log(`from.path: ${from.path}, to.path: ${to.path}`);
    // ユーザー情報更新
    let user = ""
    try {
      user = await Auth.currentAuthenticatedUser();
     } catch (e) {
      // 未ログイン時 The user is not authenticated
    }
    store.commit('setUser', user);
    if (!user && to.matched.some(record => !record.meta.isPublic)) {
      // console.log('user is null')
      next({ path: '/' })
    } else if (user) {
      // facilityID・userType更新
      const username = user.username;
      const userType = await fetchUserType(username);
      if (userType == 'admin' && to.path.indexOf('/ivr') != -1 && to.path.indexOf('/ivr-login') == -1) {
        // Adminユーザーが/ivr-login以外の医療機関画面にアクセスしようとした際は、Admin用メニュー画面に飛ばす
        next({ path: '/menu' })
      } else if (
        userType == 'user' && to.path.indexOf('/ivr') == -1 && to.path.indexOf('/login') == -1 ||
        userType === "readonly" && to.path.indexOf('/ivr') == -1 && to.path.indexOf('/login') == -1
      ) {
        // 医療機関ユーザーが/login以外のAdmin画面にアクセスしようとした際は、医療機関用メニュー画面に飛ばす
        next({ path: '/ivr-menu' })
      } else {
        next()
      }
    } else {
      next()
    }
  } catch (error) {
    console.log(error);
    // 異常系操作ログの登録
    let param = {};
    addOperationLogs('Error', 'router', 'router.beforeEach', param, error);
  } finally {
    // ローディングエフェクトOFF
    store.commit("setLoading", false);
  }
})

export default router
