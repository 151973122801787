<template>
  <div id="app">
    <!-- ローディング -->
    <Loading />
    <router-view/>
  </div>
</template>
<script>
import Loading from '@/components/loading.vue';
import * as IverVersion from "@/iver_version"
import axios from 'axios'

export default {
  components: {
    Loading
  },
  // 以下ページタイトル・メタタグ設定処理
  mounted() {
    this.setPageTitle(this.$route)
  },
  watch: {
    '$route' (to) {
      this.versionCheck();
      this.setPageTitle(to);
    }
  },
  methods: {
    setPageTitle(to) {
       // タイトルを設定
      if(to.meta.title){
        document.title = to.meta.title;
      } else {
        document.title = 'Iver'
      }

      // メタタグdescription設定
      if(to.meta.desc){
        document.querySelector("meta[name='description']").setAttribute('content', to.meta.desc)
      } else {
        document.querySelector("meta[name='description']").setAttribute('content', 'Iver')
      }
    },
    versionCheck() {
      // 開発機ではやらない
      if (IverVersion.IverVersion !== 'AWS_COMMIT_ID') {
        const request = axios.create({
          timeout: 9000
        })
        request({
          url: process.env.VUE_APP_VERSION_CHECK_URL,
          method: "get",
          params: {
            iver_version: IverVersion.IverVersion
          }
        })
        .then((res) => {
          console.log("バージョン確認：" + res.data + " IverVersion: " + IverVersion.IverVersion)
          if (res.data == 'NG') location.href = location.href + "?rand=" + Math.random();
        })
        .catch((error) => {
          console.log(error)
        })
      }
    }
  }
}
</script>
